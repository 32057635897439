.login-box,
.register-box {
  width: 360px;
  margin: 10% auto;
}
.login_failure_text {
  margin-top: 35px;
}
.text_some_another {
  font-size: 19px;
}
.orders-table-main {
  min-height: 500px;
}
.track_status_order {
  /*position: absolute;*/
  /*left: -5px;*/
  margin-right: 5px;
}
.track_status_order_production_task {
  position: absolute;
  left: -10px;
}
.cursor-pointer {
  cursor: pointer;
}
.ml-10 {
  margin-left: 10px;
}

.order-comment-form {
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  max-width: 360px;
}

.order-comment-form input {
  border: none;
  padding: 5px 10px;
  flex: 1 1 auto;
  border-bottom: 2px solid #6c757d5e;
  outline: none !important;
}

.order-comment-form button {
  background: #28a745!important;
  color: white;
  height: inherit;
  padding: 8px 10px;
  border-radius: 3px 3px 3px 0px;
}

.tooltip-parent{
  position: relative;
}
.tooltip-parent:hover  .tooltip-bl{
  display: block;
}


.tooltip-parent .tooltip-bl{
  background: white;
  padding: 12px;
  display: none;
  position: absolute;
  right: 0;
  width: max-content;
  z-index: 9;
}
.tooltip-parent .tooltip-bl li{
  list-style: none;
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
.fs-14{
  font-size: 14px!important;
}

@media (min-width: 1341px) {
  .tooltip-parent .tooltip-bl.order-count-detail{
    left: 0;
  }
}

.modal-helper-wrap .modal-bg {
  z-index: 999999999;
}
.badge{
  white-space: pre-wrap;
}